import React from "react"
import styled from "styled-components"
import HardwarePackageItem from "./hardware-package-item"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 150px;
  margin-bottom: 20px;
  margin-top: 20px;

  @media (max-width: 880px) {
    max-height: none;
    flex-wrap: nowrap;
  }
`

const HardwarePackageList = props => {
  const [ref, inView] = useInView({
    rootMargin: "50px -100px",
    triggerOnce: true,
    threshold: 0.5,
  })

  return (
    <Container
      initial={{ y: 20, opacity: 0 }}
      transition={{ duration: 0.4 }}
      animate={{ y: inView ? 0 : 20, opacity: inView ? 1 : 0 }}
      ref={ref}
    >
      {hardwarelist.map((item, index) => (
        <HardwarePackageItem
          key={index}
          title={item.title}
          description={item.description}
          icon={item.icon}
        />
      ))}
    </Container>
  )
}

export default HardwarePackageList

const hardwarelist = [
  {
    title: "Tents for Guests and Entertainment",
    icon: require("../images/icons/tent.png"),
  },
  {
    title: "Tables for dining, food, cake, cocktails",
    icon: require("../images/icons/table.png"),
  },
  {
    title: "Chairs for the Reception and Ceremony",
    icon: require("../images/icons/chairs.png"),
  },
  {
    title: "Beaded Crystal Chandiliers",
    icon: require("../images/icons/chand.png"),
  },
  {
    title: "On-site Electrical Ice Bin",
    icon: require("../images/icons/ice.png"),
  },
]
