import React from "react"
import styled from "styled-components"
import { SectionTitle } from "../../style/typography"

const Container = styled.section``

const LineItem = styled.p`
  font-weight: 300;
  color: #484848;
  font-size: 22px;
  margin-bottom: 30px;
`

const CustomerInformation = ({
  date,
  guestCount,
  fullName,
  phoneNumber,
  email,
  total,
}) => (
  <Container>
    <SectionTitle>Your Information</SectionTitle>
    <LineItem>
      {fullName} | {phoneNumber}
    </LineItem>

    <SectionTitle>The space is yours for the entire day of</SectionTitle>
    <LineItem>{date}</LineItem>

    <SectionTitle>Expected number of guests</SectionTitle>
    <LineItem>{guestCount} guests</LineItem>

    <SectionTitle>Your estimated total costs</SectionTitle>
    <LineItem>$20,000 TTD</LineItem>
  </Container>
)

export default CustomerInformation
