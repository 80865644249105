import React from "react"
import styled from "styled-components"

const ExtraOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`
const SelectionBox = styled.div`
  margin-right: 20px;
  height: 32px;
  width: 32px;
  max-width: 32px;
  border-radius: 4px;
  flex: 1;
  background: #eeeeee;
`
const OptionTitle = styled.h5`
  color: #5a5a5a;
  font-size: 20px;
  font-family: "Work Sans";
  margin: 0;
  font-weight: 500;
  flex: 1;
`

class ExtraOptionItem extends React.PureComponent {
  state = {}
  render() {
    return (
      <ExtraOption>
        <SelectionBox />
        <OptionTitle>{this.props.title}</OptionTitle>
      </ExtraOption>
    )
  }
}

export default ExtraOptionItem
