import React, { Component } from "react"
import styled from "styled-components"
import ExtraOptionItem from "./extra-option-item"
import CheckboxInput from "./checkbox-input"

const Container = styled.div``

class ExtraOptionsList extends Component {
  state = {}
  render() {
    return (
      <Container>
        {options.map((item, index) => (
          <CheckboxInput
            key={index}
            clickHandler={this.props.clickHandler}
            title={item.title}
            label={item.label}
          />
        ))}
      </Container>
    )
  }
}

export default ExtraOptionsList

const options = [
  {
    title:
      "The Villa - A four bedroom, four bath fully air-conditioned Villa. Includes bridal and groom suites",
    label: "The Villa",
    price: "",
  },
  {
    title:
      "A commercial kitchen operated by trained gourmet chefs, where wedding menus can be obtained from - $100.00 TTD/person",
    label: "Commercial kitchen with wedding menus",
    price: "",
  },
  {
    title: "Bar services based on consumption",
    label: "Bar services",
    price: "",
  },
  {
    title: "Décor and design consultation",
    label: "Dêcor consultation",
    price: "",
  },
  {
    title: "Photography packages (from dressing to reception)",
    label: "Photography",
    price: "",
  },
]
