import React from "react"
import styled from "styled-components"
import { colors } from "../../style/colors"
import { motion } from "framer-motion"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
`
const Title = styled.h2`
  font-weight: 700;
  color: ${colors.main};
  font-size: 40px;
  margin-bottom: 20px;
`
const Copy = styled.p`
  max-width: 500px;
  margin: 0 auto;
  font-weight: 300;
  font-size: 19px;
  line-height: 130%;
  margin-bottom: 30px;
`
const CTAButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`
const CTAButton = styled(motion.button)`
  border-radius: 5px;
  padding: 20px 50px;
  text-align: center;
  border: 1px solid ${colors.main};
  text-transform: uppercase;
  font-family: "Work Sans";
  font-size: 14px;
  margin: 0 15px;
  color: ${props => (props.secondary ? colors.main : "white")};
  font-weight: 600px;
  background: ${props => !props.secondary && colors.main};
  cursor: pointer;

  transition: 0.3s ease-out;

  :hover {
  }
  :focus {
    outline: none;
  }
`
const Disclaimer = styled.p`
  font-size: 14px;
  color: #717171;
  max-width: 500px;
  margin: 0 auto;
  line-height: 140%;
`

const ConfirmCTA = props => (
  <Container>
    <Title>Ready to secure your spot?</Title>
    <Copy>
      Continue to the next step to reserve the space for your special day
    </Copy>
    <CTAButtons>
      <CTAButton
        whileHover={{ x: -5 }}
        secondary
        onClick={() => window.history.back()}
      >
        Edit Your Booking
      </CTAButton>
      <CTAButton
        onClick={() => props.clickHandler()}
        whileHover={{ x: 5 }}
        type="submit"
      >
        Continue to Payment
      </CTAButton>
      {/* <form
        style={{ marginBottom: 0 }}
        action="https://sandbox.wipayfinancial.com/v1/gateway"
        method="post"
      >
        <input name="total" type="hidden" value="100" />
        <input name="phone" type="hidden" value="7188625" />
        <input name="email" type="hidden" value="andelhusbands@gmail.com" />
        <input name="name" type="hidden" value="Andel Husbands" />
        <input name="order_id" type="hidden" value="10" />
        <input
          name="return_url"
          type="hidden"
          value="https://development.madebymei-tt.com/order-confirmation/"
        />
        <input name="developer_id" type="hidden" value="1" />
      </form> */}
    </CTAButtons>
    <Disclaimer>
      We charge a small booking fee in order to secure your spot. Payment is
      handled securely by WiPay and your information never touches our servers
    </Disclaimer>
  </Container>
)

export default ConfirmCTA
