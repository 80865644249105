import styled from "styled-components"

export const Break = styled.hr`
  background: #f2f0f0;
`

export const SectionTitle = styled.h5`
  color: #484848;
  font-weight: 600;
  font-family: "Work Sans";
  font-size: 16px;
  margin-bottom: 10px;
`
