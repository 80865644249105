import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const Section = styled(motion.section)``

const Subtitle = styled(motion.h4)`
  font-weight: 400;
  font-family: "Work Sans";
  color: #646464;
  margin-bottom: 10px;
`
const SelectionTitle = styled(motion.h1)`
  font-size: 4vw;

  @media (max-width: 800px) {
    font-size: 10vw;
  }
`

const BookingIntroSection = ({ title }) => (
  <Section>
    <Subtitle
      initial={{ opacity: 0, y: -15 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        y: { duration: 1.8 },
        opacity: { duration: 2, velocity: 5 },
      }}
    >
      A quick overview of what you’ve selected
    </Subtitle>
    <SelectionTitle initial={{ opacity: 0 }} animate={{ opacity: 1, y: 0 }}>
      Booking Summary
    </SelectionTitle>
  </Section>
)

export default BookingIntroSection
