import Parse from "parse"
import axios from "axios"

//Initialize Parse
Parse.initialize(
  "09NLkiclRtAZmeoGlR9M9W0nuarlh4Wx0fEFM81R",
  "nIw2GpFWThv6hNbKaipdLjflbAxLt560l7j4V3Ef"
)
Parse.serverURL = "https://parseapi.back4app.com/"

const BOOKING_RETURN_URL = "https://flamboyant-shockley-3687fb.netlify.com/"

export async function AddToMailingList(email, name) {}

export async function CreateNewBooking(
  date,
  fullName,
  email,
  phone,
  space,
  guestCount,
  priceEstimate,
  additionalOptions
) {
  const Booking = Parse.Object.extend("Booking")
  const booking = new Booking()

  booking.set("fullName", fullName)
  booking.set("date", date)
  booking.set("email", email)
  booking.set("phoneNumber", phone)
  booking.set("space", space)
  booking.set("guestCount", guestCount)
  booking.set("priceEstimate", priceEstimate)

  //Save the booking and get the OBJECT ID

  try {
    const savedBooking = await booking.save()
    console.log("Saved!")
    return {
      success: true,
      bookingId: savedBooking.id,
    }
  } catch (err) {
    return {
      error: err.message,
    }
  }

  booking
    .save()
    .then(booking => {
      return {
        success: true,
        bookingId: booking.id,
      }
    })
    .catch(err => {
      return {
        error: err.message,
      }
    })
}

export async function loadWiPayPayment(total, phone, email, name, booking_id) {
  //   axios
  //     .post(
  //       "https://sandbox.wipayfinancial.com/v1/gateway",
  //       {
  //         total,
  //         phone,
  //         email,
  //         name,
  //         order_id: 10,
  //         developer_id: 1,
  //         return_url: BOOKING_RETURN_URL,
  //       },
  //       {
  //         headers: {
  //           "Access-Control-Allow-Headers":
  //             "Origin, Content-Type, X-Auth-Token,X-CSRF-TOKEN",
  //           "Access-Control-Allow-Origin": "*",
  //           "content-type": "application/json",
  //         },
  //       }
  //     )
  //     .then(res => {
  //       console.log(res)
  //     })
  //     .catch(err => console.log(err))
}
