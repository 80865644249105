import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`
const Icon = styled.img`
  margin: 0 15px 0 0;
  width: 30px;
`
const Items = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.h6`
  margin: 0;
  font-size: 18px;
  color: #5a5a5a;
  font-family: "Work Sans";
  font-weight: 300;
`
const Description = styled.p`
  margin: 0;
  font-size: 18px;
  color: #5a5a5a;
  font-weight: 300;
  font-family: "Work Sans";
`

const HardwarePackageItem = ({ title, description, icon }) => (
  <Container>
    <Icon src={icon} />
    <Items>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
    </Items>
  </Container>
)

export default HardwarePackageItem
